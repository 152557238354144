import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Typography } from "../../components"

const TestimonialStyled = styled.div`
  text-align: center;
`
const ContentStyled = styled.p`
  position: relative;
  font-style: italic;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
  text-indent: 10px;

  &:before,
  &:after {
    position: absolute;
    font-weight: 700;
    color: ${props => props.theme.colors.tertiary};
  }

  &:before {
    content: "«";
    left: -10px;
  }

  &:after {
    content: "»";
  }
`
const AuthorStyled = styled.p`
  margin: 20px 0 8px 0;
  color: ${props => props.theme.colors.tertiary};
`
const DateStyled = styled.p`
  font-size: 12px;
  color: ${props => props.theme.colors.tertiary};
`
const TagsStyled = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
`
const TagStyled = styled.p`
  font-size: 14px;
  padding: 7px 16px;
  border-radius: 20px;
  margin: 0 10px 10px 10px;
  border: solid 1px ${props => props.theme.colors.grey};
  color: ${props => props.theme.colors.secondary};
`

const Testimonial = ({ title, content, author, date, tags }) => {
  return (
    <TestimonialStyled>
      <Typography variant="medium">{title}</Typography>
      {tags && (
        <TagsStyled>
          {tags.map((tag, i) => (
            <TagStyled key={`tag-${i}-${title}`}>{tag}</TagStyled>
          ))}
        </TagsStyled>
      )}

      <ContentStyled>{content}</ContentStyled>
      <AuthorStyled>{author}</AuthorStyled>
      <DateStyled>Le {date}</DateStyled>
    </TestimonialStyled>
  )
}

Testimonial.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default Testimonial
