import React from "react"
import styled, { ThemeProvider } from "styled-components"
import PropTypes from "prop-types"
import theme from "../../theme"
import GlobalStyle from "../../theme/global"
import { CookieBanner, Header, Footer } from "../../components"

const MainStyled = styled.main`
  display: block;
  padding-top: 71px;
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <MainStyled>
      <GlobalStyle />
      <Header />
      {children}
      <Footer />
      <CookieBanner />
    </MainStyled>
    <script
      type="text/javascript"
      src="https://identity.netlify.com/v1/netlify-identity-widget.js"
    ></script>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout
