import HKGroteskBoldWoff from "../fonts/hkgrotesk-bold.woff"
import HKGroteskBoldWoff2 from "../fonts/hkgrotesk-bold.woff2"
import HKGroteskMediumWoff from "../fonts/hkgrotesk-medium.woff"
import HKGroteskMediumWoff2 from "../fonts/hkgrotesk-medium.woff2"
import HKGroteskMediumItalicWoff from "../fonts/hkgrotesk-mediumitalic.woff"
import HKGroteskMediumItalicWoff2 from "../fonts/hkgrotesk-mediumitalic.woff2"

export default {
  HKGrotesk: {
    woff: {
      bold: HKGroteskBoldWoff,
      medium: HKGroteskMediumWoff,
      mediumItalic: HKGroteskMediumItalicWoff,
    },
    woff2: {
      bold: HKGroteskBoldWoff2,
      medium: HKGroteskMediumWoff2,
      mediumItalic: HKGroteskMediumItalicWoff2,
    },
  },
}
