import React, { useEffect, useState } from "react"
import Cookies from "universal-cookie"
import styled from "styled-components"
import { Button, Container } from "../../components"
import { Link as GLink } from "gatsby"

const CookieBannerStyled = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px 0;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 12px 12px 10px rgba(48, 41, 41, 0.2);
`
const LinkStyled = styled(GLink)`
  display: inline-block;
  text-decoration: underline;
`
const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${props => props.theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const TextStyled = styled.p`
  line-height: 1.4;
  margin-right: auto;

  ${props => props.theme.mediaQueries.sm} {
    width: calc(100% - 150px);
  }
`

const ButtonContainerStyled = styled.div`
  margin-top: 20px;
  min-width: 155px;
  ${props => props.theme.mediaQueries.sm} {
    margin-top: 0px;
    margin-left: 30px;
  }
`

const CookieBanner = () => {
  const [hasCookie, setHasCookie] = useState(false)

  const handleCookie = value => {
    const cookies = new Cookies()
    cookies.set("gatsby-gdpr-google-analytics", value, {
      sameSite: true,
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
    })
    setHasCookie(true)
    // eslint-disable-next-line no-undef
    document.location.reload(true)
  }

  useEffect(() => {
    const cookies = new Cookies()
    const gaCookie = cookies.get("gatsby-gdpr-google-analytics")
    setHasCookie(typeof gaCookie !== "undefined")
  }, [])

  return !hasCookie ? (
    <CookieBannerStyled>
      <Container>
        <WrapperStyled>
          <TextStyled>
            Ce site utilise{" "}
            <LinkStyled to="/mentions-legales">des cookies</LinkStyled> pour
            améliorer votre expérience utilisateur. En cliquant sur
            &quot;j&apos;accepte&quot;, vous autorisez à recevoir des cookies de
            ce site.
          </TextStyled>
          {/* <ButtonContainerStyled>
            <Button
              variant="outlined"
              tag="button"
              onClick={() => handleCookie(false)}
            >
              Je refuse
            </Button>
          </ButtonContainerStyled> */}
          <ButtonContainerStyled>
            <Button tag="button" onClick={() => handleCookie(true)}>
              J&apos;accepte
            </Button>
          </ButtonContainerStyled>
        </WrapperStyled>
      </Container>
    </CookieBannerStyled>
  ) : (
    <></>
  )
}

CookieBanner.propTypes = {}

CookieBanner.defaultProps = {}

export default CookieBanner
