import { createGlobalStyle } from "styled-components"
import reset from "styled-reset-advanced"
import fonts from "../fonts"
import theme from "../theme"
const GlobalStyle = createGlobalStyle`
  ${reset};

  @font-face {
    font-family: "HK Grotesk";
    src: url('${fonts.HKGrotesk.woff2.bold}') format("woff2"),
      url('${fonts.HKGrotesk.woff.bold}') format("woff");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "HK Grotesk";
    src: url('${fonts.HKGrotesk.woff2.medium}') format("woff2"),
      url('${fonts.HKGrotesk.woff.medium}') format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "HK Grotesk";
    src: url('${fonts.HKGrotesk.woff2.mediumItalic}') format("woff2"),
      url('${fonts.HKGrotesk.woff.mediumItalic}') format("woff");
    font-weight: medium;
    font-style: italic;
  }  
 
  body {
    font-family: ${theme.typography.primary};
    font-weight: 400;
    color: ${theme.colors.primary}
  }

  li{
    padding: 4px 0;
    line-height: 1.7;
    font-weight: 700;
    padding-left: 30px;
    position: relative;
    
  }
  li:before{
    content: "\\2192";
    font-size: 25px;
    position: absolute;
    top: -8px;
    left: 0;
    color: ${theme.colors.secondary}
  }

  p{    
    lineHeight: 1.7;
    marginBottom: 16;
  }

  /* IOS FIXES */
  input,textarea {
    -webkit-touch-callout: default !important;
    -webkit-user-select: text !important;
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
`

export default GlobalStyle
