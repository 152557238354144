import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { Container } from ".."

const TitleStyled = styled.h1`
  position: relative;
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
  display: inline-block;
  padding-bottom: 30px;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.white};

  ${props => props.theme.mediaQueries.sm} {
    padding-bottom: 11px;
    font-size: 55px;
  }

  &::after {
    content: "";
    display: block;
    height: 4px;
    width: 18%;
    position: absolute;
    bottom: 0;
    left: 50%;
    background: ${props => props.theme.colors.tertiary};
    transform: translateX(-50%);

    ${props => props.theme.mediaQueries.xs} {
      width: 100%;
    }
  }

  ${props =>
    props.interior &&
    css`
      color: ${props => props.theme.colors.tertiary};
      padding-bottom: 0px;
      &::after {
        display: none;
      }
    `};
`

const SubtitleStyled = styled.h2`
  font-size: 30px;
  line-height: 35px;
  color: #fff;
  ${props => props.theme.mediaQueries.xs} {
    font-size: 35px;
  }
`

const BannerStyled = styled.div`
  position: relative;
  width: 100%;
  background: ${props => props.theme.colors.primary};
  padding: 60px 20px;
  text-align: center;

  &::after {
    content: "";
    display: none;
    height: 70px;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    background: ${props => props.theme.colors.primary};
  }

  ${props =>
    !props.interior &&
    css`
      &::after {
        display: block;
      }
    `}
`

const ChildrenStyled = styled.div`
  margin-top: 30px;
`

const Banner = ({ title, subheading, children, interior }) => {
  return (
    <BannerStyled interior={interior}>
      <Container>
        <TitleStyled interior={interior} tag="h1">
          {title}
        </TitleStyled>
        {subheading && <SubtitleStyled>{subheading}</SubtitleStyled>}
        {children && <ChildrenStyled>{children}</ChildrenStyled>}
      </Container>
    </BannerStyled>
  )
}

Banner.propTypes = {
  interior: PropTypes.bool,
  title: PropTypes.string,
  subheading: PropTypes.string,
  children: PropTypes.any,
}

Banner.defaultProps = {
  interior: true,
}

export default Banner
