import React from "react"
import theme from "../../theme"

const Star = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.481 19.481"
      height="25"
      width="33"
      fill={theme.colors.secondary}
    >
      <path d="M10.201.758l2.478 5.865 6.344.545a.5.5 0 01.285.876l-4.812 4.169 1.442 6.202a.5.5 0 01-.745.541l-5.452-3.288-5.452 3.288a.5.5 0 01-.745-.541l1.442-6.202-4.813-4.17a.5.5 0 01.285-.876l6.344-.545L9.28.758a.5.5 0 01.921 0z" />
    </svg>
  )
}

export default Star
