import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Logo from "../../img/logo.png"
import { Container } from ".."
import { Link as GLink } from "gatsby"
import Button from "../Button"
import { Close, Menu } from "../Icon"

const HeaderStyled = styled.header`
  padding: 10px 0;
  border-bottom: solid 1px ${props => props.theme.colors.grey};
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 12px 12px -10px rgba(48, 41, 41, 0.1);
`

const BrandStyled = styled(GLink)`
  display: flex;
  align-items: center;
`
const LogoStyled = styled.img`
  height: 50px;
  width: auto;
`
const TitleStyled = styled.p`
  margin-left: 15px;
  font-weight: 700;
  font-size 20px;
  margin-bottom: 2px;
`

const NavStyled = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MenuContainerStyled = styled.div`
  position: fixed;
  top: 0;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background: ${props => props.theme.colors.grey};
  left: 0;
  display: flex;
  flex-direction: column;
  display: block;
  transition: all 0.3s ease;
  transform: ${props => (props.open ? "translate(0)" : "translate(100%)")};
  opacity: ${props => (props.open ? 1 : 0)};

  ${props => props.theme.mediaQueries.md} {
    position: relative;
    display: block;
    height: auto;
    width: auto;
    background: transparent;
    transform: none;
    opacity: 1;
  }
`

const MenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${props => props.theme.colors.darkGrey};
  max-width: 90vw;
  margin: 0 auto;

  ${props => props.theme.mediaQueries.md} {
    max-width: 100%;
    border: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`
const MenuItemStyled = styled.div`
  &:first-child {
    margin-top: 20px;
  }
  &:last-child {
    margin-top: 20px;
    padding: 12px 0;
    border: solid 1px ${props => props.theme.colors.darkGrey};
    border-left: 0;
    border-right: 0;
  }
  ${props => props.theme.mediaQueries.md} {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-top: 0;
      padding: 0;
      border: 0;
    }
  }
`
const StyledLink = styled(GLink)`
  padding: 20px 0;
  display: block;
  font-size 18px;
  font-weight: 700;
  position: relative;

  &:after {    
    content: "";
    display:none;
    height: 2px;
    width: calc(100% - 40px);
    background: ${props => props.theme.colors.primary};
    position: absolute;
    bottom: -7px;
    left: 20px; 
    transform: scale(0);
    transition: transform .2s cubic-bezier(.47,.07,.21,1.4).1s;

    ${props => props.theme.mediaQueries.md} {
      display: block;
    }
  }

  &:hover:after,
  &.active-link:after {   
    transform: scale(1); 
    transition: transform .2s cubic-bezier(.47,.07,.21,1.4).1s;
  }


  ${props => props.theme.mediaQueries.md} {
    padding: 0 20px;
  }
`

const IconCloseContainerStyled = styled.div`
  height: 80px;
  padding: 30px;
  padding-right: 0;
  cursor: pointer;
  margin-left: auto;
  text-align: right;
  display: block;
  max-width: 90vw;
  margin: 0 auto;

  ${props => props.theme.mediaQueries.md} {
    display: none;
  }
`

const IconMenuContainerStyled = styled.div`
  padding: 20px;
  padding-right: 0;
  margin-left: auto;
  cursor: pointer;
  display: inline-block;
  opacity: ${props => (props.show ? 1 : 0)};

  ${props => props.theme.mediaQueries.md} {
    display: none;
  }
`
const ButtonContainerStyled = styled.div`
  position: relative;
  overflow: hidden;
`
const ButtonStyled = styled.div`
  position: relative;
  transition: transform 0.4s;
  width: 100%;

  ${props => props.theme.mediaQueries.xs} {
    width: auto;
  }

  &:first-child {
    position: absolute;
    transform: translateY(${props => (props.hasScrolled ? 0 : "-70px")});
    z-index: 1;
  }
  &:last-child {
    transform: translateY(${props => (props.hasScrolled ? "70px" : 0)});
  }
`

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.onscroll = function() {
      // eslint-disable-next-line no-undef
      if (window.pageYOffset > 0) {
        if (hasScrolled !== true) {
          setHasScrolled(true)
        }
      } else {
        setHasScrolled(false)
      }
    }
    return () => {
      // eslint-disable-next-line no-undef
      window.onscroll = null
    }
  })

  return (
    <HeaderStyled>
      <Container>
        <NavStyled>
          <BrandStyled to="/">
            <LogoStyled alt="Logo Courtier Prêt Immo" src={Logo}></LogoStyled>
            <TitleStyled>
              Courtier
              <br />
              Prêt Immo
            </TitleStyled>
          </BrandStyled>
          <IconMenuContainerStyled
            show={!isMenuOpen}
            onClick={() => setIsMenuOpen(true)}
          >
            <Menu />
          </IconMenuContainerStyled>
          <MenuContainerStyled open={isMenuOpen}>
            <IconCloseContainerStyled
              show={isMenuOpen}
              onClick={() => setIsMenuOpen(false)}
            >
              <Close />
            </IconCloseContainerStyled>
            <MenuStyled>
              <MenuItemStyled>
                <StyledLink activeClassName="active-link" to="/">
                  Accueil
                </StyledLink>
              </MenuItemStyled>
              <MenuItemStyled>
                <StyledLink
                  activeClassName="active-link"
                  to="/vos-projets-immobiliers"
                >
                  Vos projets
                </StyledLink>
              </MenuItemStyled>
              <MenuItemStyled>
                <StyledLink activeClassName="active-link" to="/nos-missions">
                  Nos missions
                </StyledLink>
              </MenuItemStyled>
              <MenuItemStyled>
                <StyledLink activeClassName="active-link" to="/temoignages">
                  Témoignages
                </StyledLink>
              </MenuItemStyled>
              <MenuItemStyled>
                <StyledLink activeClassName="active-link" to="/actualites">
                  Actualités
                </StyledLink>
              </MenuItemStyled>
              <MenuItemStyled>
                <ButtonContainerStyled>
                  <ButtonStyled hasScrolled={hasScrolled}>
                    <Button to="/nous-contacter">Nous contacter</Button>
                  </ButtonStyled>
                  <ButtonStyled hasScrolled={hasScrolled}>
                    <Button to="/nous-contacter" variant="outlined">
                      Nous contacter
                    </Button>
                  </ButtonStyled>
                </ButtonContainerStyled>
              </MenuItemStyled>
            </MenuStyled>
          </MenuContainerStyled>
        </NavStyled>
      </Container>
    </HeaderStyled>
  )
}

export default Header
