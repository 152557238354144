/* eslint-disable no-undef */
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Container, Button, Pagination } from "../../components"
import imgDesktop from "../../../static/img/testimonial.jpg"
import imgMobile from "../../../static/img/testimonial_xs.jpg"

const ArchiveStyled = styled.div`
  margin: 40px 0;
`

const ListStyled = styled.div`
  & > * {
    padding: 40px 0;
    border-bottom: solid 1px ${props => props.theme.colors.lightGrey};
  }
`

const FooterStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: center;

  ${props => props.theme.mediaQueries.sm} {
    justify-content: space-between;
  }
`

const TotalStyled = styled.div`
  color: ${props => props.theme.colors.darkGrey};
  font-size: 14px;
  width: 15%;
  text-transform: uppercase;
  display: none;

  ${props => props.theme.mediaQueries.sm} {
    display: block;
  }
`

const GoUpStyled = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-size: 14px;
  width: 15%;
  cursor: pointer;
  display: none;

  &:after {
    content: "\\2191";
    font-size: 18px;
    margin-left: 5px;
  }

  ${props => props.theme.mediaQueries.sm} {
    display: block;
  }
`

const GalleryStyled = styled.div`
  margin: 40px 0;
  background-image: url(${imgMobile});
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 565px;
  width: 100%;

  ${props => props.theme.mediaQueries.sm} {
    background-image: url(${imgDesktop});
    height: 400px;
  }
`

const ButtonContainerStyled = styled.div`
  text-align: center;
`

const Archive = ({ total = 0, currentPage = 1, basePath, children }) => {
  return (
    <ArchiveStyled>
      <Container>
        <ListStyled>{children}</ListStyled>
        <FooterStyled>
          <TotalStyled>{total} avis</TotalStyled>
          <Pagination
            totalCount={total}
            currentPage={currentPage}
            basePath={basePath}
          />
          <GoUpStyled onClick={() => window.scrollTo(0, 0)}>
            Haut de page
          </GoUpStyled>
        </FooterStyled>
        <GalleryStyled />
        <ButtonContainerStyled>
          <Button
            tag="a"
            href="https://g.page/cpi85"
            target="_blank"
            rel="noreferrer noopener"
          >
            Laisser un avis
          </Button>
        </ButtonContainerStyled>
      </Container>
    </ArchiveStyled>
  )
}

Archive.propTypes = {
  total: PropTypes.number,
  currentPage: PropTypes.number,
  basePath: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default Archive
