import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import utils from "../../services/utils"
import theme from "../../theme"
import { Typography } from "../../components"

const ListStyled = styled.div`
  margin-top: 40px;
`
const RowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  text-align: left;
`

const ItemStyled = styled.div`
  padding: 20px;
  position: relative;
  width: 100%;

  &:after,
  &:before {
    content: "";
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    background: ${props => props.theme.colors.grey};
    width: 100%;
    display: block;
  }

  &:after {
    bottom: 0;
  }
  &:before {
    top: 0;
  }

  ${props => props.theme.mediaQueries.sm} {
    width: 50%;
  }
`

const List = ({ items }) => {
  const chunkedList = utils.chunk(items, 2)

  return (
    <ListStyled>
      {chunkedList.map((row, i) => (
        <RowStyled key={`row-${i}`}>
          {row.map((item, j) => (
            <ItemStyled key={`row-${i}-item-${j}`}>
              <Typography
                tag="h3"
                variant="small"
                color={i % 2 ? theme.colors.primary : theme.colors.secondary}
              >
                {item.title}
              </Typography>
              <Typography>{item.content}</Typography>
            </ItemStyled>
          ))}
        </RowStyled>
      ))}
    </ListStyled>
  )
}

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
}

List.defaultProps = {
  container: false,
}

export default List
