import React from "react"
import styled from "styled-components"
import { variant } from "styled-system"
import PropTypes from "prop-types"
import theme from "../../theme"
import GatsbyLink from "gatsby-link"

const tag = ""

const ButtonStyled = styled(tag)(
  {
    cursor: "pointer",
    textAlign: "center",
    position: "relative",
    display: "inline-block",
    textTransform: "uppercase",
    padding: "15px 30px",
    borderRadius: "30px",
    border: "2px solid",
    letterSpacing: 1,
    fontWeight: 700,
    borderColor: theme.colors.secondary,
    zIndex: 0,
    width: "100%",
    transition: "all 0.2s",
    overflow: "hidden",
    "&:hover": {
      opacity: 0.7,
    },
    [theme.mediaQueries.xs]: {
      width: "auto",
    },
  },
  variant({
    variants: {
      contained: {
        background: theme.colors.secondary,
        color: theme.colors.white,
      },
      outlined: {
        background: theme.colors.white,
        color: theme.colors.secondary,
      },
    },
  })
)

const Button = ({ tag = GatsbyLink, children, ...props }) => {
  const WithComponent = ButtonStyled.withComponent(tag)
  return <WithComponent {...props}>{children}</WithComponent>
}

Button.propTypes = {
  children: PropTypes.any,
  tag: PropTypes.any,
  variant: PropTypes.oneOf(["contained", "outlined"]),
}

Button.defaultProps = {
  tag: GatsbyLink,
  variant: "contained",
}

export default Button
