import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import theme from "../../theme"

const SeparatorStyled = styled.span`
  height: 5px;
  width: ${props => (props.variant === "default" ? "50px" : "35px")};
  display: block;
  background: ${props => props.color};
  margin: ${props => (props.variant === "default" ? "25px 0" : "15px 0")};
`

const Separator = ({ color, variant }) => (
  <SeparatorStyled variant={variant} color={color} />
)

Separator.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.oneOf(["default", "small"]),
}

Separator.defaultProps = {
  color: theme.colors.tertiary,
  variant: "default",
}

export default Separator
