import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Star } from "../Icon"

const WrapperStyled = styled.div`
  display: flex;
  margin-bottom: ${props => (props.variant === "default" ? "40px" : "0")};
  margin-top: ${props => (props.variant === "default" ? "0px" : "-30px")};
  align-items: center;
  justify-content: center;
`

const StarsWrapper = styled.div`
  padding: 0 8px;
`

const TextStyled = styled.p`
  padding: 0 8px;
  font-weight: 700;
  color: ${props =>
    props.variant === "default"
      ? props.theme.colors.darkGrey
      : props.theme.colors.white};
`

const Stars = ({ variant }) => {
  return (
    <WrapperStyled variant={variant}>
      <StarsWrapper>
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </StarsWrapper>
      <TextStyled variant={variant}>4,9 sur 5</TextStyled>
    </WrapperStyled>
  )
}

Stars.propTypes = {
  variant: PropTypes.oneOf(["default", "inside-banner"]),
}

Stars.defaultProps = {
  variant: "default",
}

export default Stars
