import React from "react"

const Arrow = () => {
  return (
    <svg
      width="18"
      height="7"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="b">
          <use xlinkHref="#a" clipRule="evenodd" />
        </clipPath>
        <path id="a" d="M0 0h18v7H0z" />
      </defs>
      <g clipPath="url(#b)">
        <use xlinkHref="#a" fill="#FFF" />
        <path
          d="M13 7l5-3.563L13 0M17.438 3.5H.563"
          fill="none"
          stroke="#3CBEAC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default Arrow
