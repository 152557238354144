import React from "react"
import styled from "styled-components"
import { Container, Typography, Separator } from "../../components"
import { Link as GLink } from "gatsby"

const FooterWrapperStyled = styled.footer`
  padding: 40px 0;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.primary};
`
const FooterStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const InfoStyled = styled.div`
  padding-right: 0;
  width: 100%;

  ${props => props.theme.mediaQueries.sm} {
    width: 50%;
    padding-right: 100px;
  }
`

const CopyrightStyled = styled.p`
  font-size: 18px;
  font-weight: 700;
`

const NavStyled = styled.nav`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  ${props => props.theme.mediaQueries.sm} {
    width: 50%;
    margin-top: 0;
  }
`
const SectionStyled = styled.section`
  width: 100%;
  margin-bottom: 10px;

  ${props => props.theme.mediaQueries.xs} {
    width: 50%;
    margin-bottom: 0;
  }
`
const LinkStyled = styled(GLink)`
  display: block;
  margin: 15px 0;
`

const Footer = () => {
  return (
    <FooterWrapperStyled>
      <Container>
        <FooterStyled>
          <InfoStyled>
            <Typography variant="body2">
              Courtier prêt immo est un courtier indépendant de courtage
              implanté à Challans en Vendée. L’agence propose ses services sur
              le 85 et le 44.
              <br />3 Courtiers sont disponibles et réactifs pour vous écouter,
              accompagner et négocier votre projet de financement immobilier.
              <br />
              Nous avons des partenariats avec les banques et notre volume de
              dossiers prêts permet une négociation optimisée des conditions
              bancaires.
            </Typography>
            <Separator />
            <CopyrightStyled>© 2020 Courtier Prêt Immo</CopyrightStyled>
          </InfoStyled>
          <NavStyled>
            <SectionStyled>
              <Typography variant="small">Plan du site</Typography>
              <LinkStyled to="/">Accueil</LinkStyled>
              <LinkStyled to="/vos-projets-immobiliers">Vos projets</LinkStyled>
              <LinkStyled to="/nos-missions">Nos missions</LinkStyled>
              <LinkStyled to="/temoignages">Témoignages</LinkStyled>
              <LinkStyled to="/actualites">Actualités</LinkStyled>
              <LinkStyled to="/nous-contacter">Contactez-nous</LinkStyled>
            </SectionStyled>
            <SectionStyled>
              <Typography variant="small">Nous contacter</Typography>
              <Typography>
                Courtier Prêt Immo
                <br />
                183 rue Carnot
                <br />
                85300 Challans
                <br />
                02 28 10 75 82
              </Typography>
              <Typography variant="small">Légal</Typography>
              <LinkStyled to="/mentions-legales">Mentions légales</LinkStyled>
            </SectionStyled>
          </NavStyled>
        </FooterStyled>
      </Container>
    </FooterWrapperStyled>
  )
}

export default Footer
