import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const SliderWrapperStyled = styled.div`
  overflow: auto;
  margin-top: 50px;
`

const SliderStyled = styled.div`
  display: flex;
  justify-content: space-around;
`
const SlideStyled = styled.img`
  max-height: 80px;
  margin: 0 20px;
`

const Slider = ({ slides }) => {
  return (
    <SliderWrapperStyled>
      <SliderStyled>
        {slides.map((slide, index) => (
          <SlideStyled key={`s-${index}`} alt={slide.alt} src={slide.src} />
        ))}
      </SliderStyled>
    </SliderWrapperStyled>
  )
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Slider
