const breakpoints = ["576px", "768px", "992px", "1280px"]

export default {
  typography: {
    primary: `"HK Grotesk", sans-serif`,
  },
  colors: {
    primary: `#22313F`, //dark blue
    secondary: `#00C9AC`, //blue
    secondaryHover: `#76d1c4`, //lightèblue
    tertiary: `#D4B254`, //gold
    white: `#ffffff`, //white
    grey: `#f8f5f4`, //grey
    lightGrey: `#f1f1f1`, //light grey
    darkGrey: `#d2d2d2`, //dark grey
  },
  breakpoints,
  mediaQueries: {
    xs: `@media screen and (min-width: ${breakpoints[0]})`,
    sm: `@media screen and (min-width: ${breakpoints[1]})`,
    md: `@media screen and (min-width: ${breakpoints[2]})`,
    lg: `@media screen and (min-width: ${breakpoints[3]})`,
  },
}
