import React, { Fragment } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link as GLink } from "gatsby"

const PaginationStyled = styled.div`
  display: flex;

  ${props => props.theme.mediaQueries.md} {
    flex-direction: row;
  }
`

const ItemStyled = styled(GLink)`
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  color: ${props =>
    props.active === "true"
      ? props.theme.colors.primary
      : props.theme.colors.darkGrey};
  ${props => props.theme.mediaQueries.md} {
  }
`
const EllipsisStyled = styled.span`
  padding: 5px 10px;
  margin: 0 5px;
  color: ${props => props.theme.colors.darkGrey}
  border: solid 1px;
`

const ActionStyled = styled(GLink)`
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  color: ${props =>
    props.disabled ? props.theme.colors.darkGrey : props.theme.colors.primary};
`

const Pagination = ({ totalCount, currentPage, basePath }) => {
  const perPage = 6
  const pageCount = Math.ceil(totalCount / perPage)
  const delta = 2
  const left = currentPage - delta
  const right = currentPage + delta + 1
  const pages = Array.from({ length: pageCount }, (v, k) => k + 1).filter(
    i => i && i >= left && i < right
  )

  return (
    <PaginationStyled>
      <ActionStyled
        disabled={currentPage === 1}
        to={`${basePath}/${currentPage > 2 ? currentPage - 1 : ""}`}
      >
        &lt;
      </ActionStyled>

      {pages.map((page, index) => (
        <Fragment key={`page-testimonial-${page}`}>
          {index === 0 && page > 1 && <EllipsisStyled>...</EllipsisStyled>}

          <ItemStyled
            active={currentPage === page ? "true" : "false"}
            to={`${basePath}/${page > 1 ? page : ""}`}
          >
            {page}
          </ItemStyled>
          {index + 1 === pages.length && page < pageCount && (
            <EllipsisStyled>...</EllipsisStyled>
          )}
        </Fragment>
      ))}
      <ActionStyled
        disabled={currentPage === pageCount}
        to={`${basePath}/${currentPage + 1}`}
      >
        &gt;
      </ActionStyled>
    </PaginationStyled>
  )
}

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  basePath: PropTypes.string.isRequired,
}

export default Pagination
