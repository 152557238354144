import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Container } from "../../components"

const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 60px;

  ${props => props.theme.mediaQueries.md} {
    flex-direction: row;
  }
`

const ItemStyled = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  ${props => props.theme.mediaQueries.md} {
    margin-bottom: 0;
    width: calc(33% - 40px);
  }

  ${props => props.theme.mediaQueries.lg} {
    width: calc(33% - 80px);
  }
`

const Grid = ({ children, container }) => {
  return container ? (
    <Container>
      <ContainerStyled>{children}</ContainerStyled>
    </Container>
  ) : (
    <ItemStyled>{children}</ItemStyled>
  )
}

Grid.propTypes = {
  container: PropTypes.bool,
  children: PropTypes.any,
}

Grid.defaultProps = {
  container: false,
}

export default Grid
