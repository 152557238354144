import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { variant } from "styled-system"
import theme from "../../theme"
import Container from "../Container"
import BackgroundImage from "gatsby-background-image"

const SectionStyled = styled("section")(
  {
    position: "relative",
    overflow: "hidden",
    background: theme.colors.grey,
    margin: "80px 0",
  },
  variant({
    variants: {
      default: {
        textAlign: "center",
        background: theme.colors.white,
      },
    },
  })
)

const ContentStyled = styled("div")(
  variant({
    variants: {
      "image-right": {
        padding: "65px 20px",
        [theme.mediaQueries.md]: {
          marginLeft: "50%",
          padding: "100px 60px",
        },
        [theme.mediaQueries.lg]: {
          padding: "120px 80px",
        },
      },
      "image-left": {
        padding: "65px 20px",
        [theme.mediaQueries.md]: {
          padding: "100px 60px",
          marginRight: "50%",
        },
        [theme.mediaQueries.lg]: {
          padding: "120px 80px",
        },
      },
    },
  })
)

let tag = ""

const ImageContainerStyled = styled(tag)(
  {
    display: "flex",
    height: "350px",
    width: "100%",
    [theme.mediaQueries.sm]: {
      height: "300px",
    },

    [theme.mediaQueries.md]: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      height: "100%",
      width: "50%",
    },
  },
  variant({
    variants: {
      "image-right": {
        [theme.mediaQueries.md]: {
          left: 0,
        },
      },
      "image-left": {
        [theme.mediaQueries.md]: {
          right: 0,
        },
      },
    },
  })
)

const ImageStyled = styled(BackgroundImage)(
  {
    flex: 1,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
  },
  variant({
    variants: {
      "image-right": {
        backgroundPosition: "80% 50%",
      },
      "image-left": {
        backgroundPosition: "20% 50%",
      },
    },
  })
)

const Section = ({ variant, image, link, children }) => {
  const tag = link ? "a" : "div"
  const imageAttr = link
    ? {
        href: link,
        target: "_blank",
        rel: "noreferrer noopener",
        variant,
      }
    : {
        variant,
      }

  const ImageContainerStyledWithComponent = ImageContainerStyled.withComponent(
    tag
  )

  return (
    <SectionStyled variant={variant}>
      {image && (
        <ImageContainerStyledWithComponent {...imageAttr}>
          <ImageStyled
            Tag="div"
            fluid={image}
            backgroundColor={theme.colors.grey}
            variant={variant}
          />
        </ImageContainerStyledWithComponent>
      )}
      <Container>
        <ContentStyled variant={variant}>{children}</ContentStyled>
      </Container>
    </SectionStyled>
  )
}

Section.propTypes = {
  children: PropTypes.any,
  image: PropTypes.object,
  link: PropTypes.string,
  variant: PropTypes.oneOf(["default", "image-right", "image-left"]),
}

Section.defaultProps = {
  variant: "default",
}

export default Section
