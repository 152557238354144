import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Typography } from "../../components"

const TimelineStyled = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  ${props => props.theme.mediaQueries.md} {
    margin-left: -20px;
    margin-right: -20px;
  }
`

const ItemStyled = styled.div`
  padding: 20px;
  position: relative;
  width: 100%;
  text-align: left;
  padding-left: 40px;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    display: inline-block;
    height: 15px;
    width: 15px;
    background: ${props => props.theme.colors.white};
    border-radius: 100%;
    border: solid 2px ${props => props.theme.colors.tertiary};

    top: 26px;
    left: 0;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    display: inline-block;
    background: ${props => props.theme.colors.tertiary};

    left: 7px;
    width: 2px;
    height: 100%;
  }

  &:first-child:after {
    top: 30px;
  }
  &:last-child:after {
    height: calc(100% - 30px);
  }

  ${props => props.theme.mediaQueries.md} {
    width: 25%;
    text-align: center;
    padding-left: 20px;

    &:before {
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      left: 0;
      width: 100%;
      height: 2px;
    }

    &:first-child:after {
      top: 0;
      left: 50%;
      width: 50%;
    }
    &:last-child:after {
      right: 50%;
      width: 50%;
      height: 2px;
    }
  }
`

const Timeline = ({ items }) => {
  return (
    <TimelineStyled>
      {items.map((item, j) => (
        <ItemStyled key={`timeline-item-${j}`}>
          <Typography variant="small">{item.title}</Typography>
          <Typography>{item.content}</Typography>
        </ItemStyled>
      ))}
    </TimelineStyled>
  )
}

Timeline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
}

Timeline.defaultProps = {
  container: false,
}

export default Timeline
