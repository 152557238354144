import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { variant, fontSize, color, fontWeight, lineHeight } from "styled-system"

const tag = ""

const StyledDynamicComponent = styled(tag)(
  variant({
    variants: {
      large: {
        fontSize: [30, 35, 35, 35, 40],
        fontWeight: 700,
        lineHeight: 1.3,
        marginBottom: 16,
      },
      medium: {
        fontSize: 22,
        fontWeight: 700,
        lineHeight: 1.3,
        marginBottom: 12,
      },
      small: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: 1.3,
        marginBottom: 12,
      },
      body1: {
        lineHeight: 1.7,
        fontSize: 15,
        marginBottom: 16,
      },
      body2: {
        fontSize: 18,
        lineHeight: 1.7,
        marginBottom: 16,
      },
      body3: {
        lineHeight: 1.7,
        fontWeight: 700,
      },
    },
  }),
  fontSize,
  color,
  fontWeight,
  lineHeight
)

const Typography = ({ tag = "p", children, ...props }) => {
  const WithComponent = StyledDynamicComponent.withComponent(tag)
  return <WithComponent {...props}>{children}</WithComponent>
}

Typography.propTypes = {
  children: PropTypes.any,
  tag: PropTypes.string,
}

Typography.defaultProps = {
  variant: "body1",
  tag: "p",
}

export default Typography
