import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { variant } from "styled-system"
import theme from "../../theme"
import { Link } from "../../components"

const CardStyled = styled("div")(
  {
    textAlign: "left",
    padding: 40,
    border: `solid 1px ${theme.colors.lightGrey}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  variant({
    variants: {
      default: {
        paddingTop: 50,
        background: theme.colors.white,
      },
      full: {
        background: theme.colors.grey,
        justifyContent: "center",
      },
    },
  })
)

const TitleStyled = styled("h3")(
  {
    position: "relative",
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.3,

    "&::after": {
      display: "block",
      height: 1,
      width: "100%",
      position: "absolute",
      bottom: 0,
      background: theme.colors.lightGrey,
    },
  },
  variant({
    variants: {
      default: {
        paddingBottom: 18,
        marginBottom: 20,
        color: theme.colors.tertiary,
        "&::after": {
          content: "''",
        },
      },
      full: {},
    },
  })
)

const LinkWrapperStyled = styled.div`
  padding-top: 15px;
  margin-top: auto;
`

const Card = ({ title, link, variant, children }) => {
  return (
    <CardStyled variant={variant}>
      <TitleStyled variant={variant}>{title}</TitleStyled>
      {variant !== "full" && (
        <>
          {children}
          {link && link.label && (
            <LinkWrapperStyled>
              <Link to={`/${link.to}`}>{link.label}</Link>
            </LinkWrapperStyled>
          )}
        </>
      )}
    </CardStyled>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  variant: PropTypes.oneOf(["default", "full"]),
}

Card.defaultProps = {
  interior: false,
  variant: "default",
}

export default Card
