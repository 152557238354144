import React, { Fragment } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { Typography, Container, Separator } from "../../components"
import theme from "../../theme"

const ContactStyled = styled.div`
  padding: 80px 0 40px 0;
  display: flex;
  flex-wrap: wrap;
  ${props => props.theme.mediaQueries.md} {
  }
`

const ContentStyled = styled.section`
  width: 100%;
  ${props => props.theme.mediaQueries.md} {
    width: 60%;
    padding-right: 30px;
  }
  ${props => props.theme.mediaQueries.lg} {
    width: 55%;
    padding-right: 50px;
  }
`
const ContactInfoStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 40px 0;
  padding-top: 40px;
  border-top: solid 1px ${props => props.theme.colors.grey};
`
const SectionStyled = styled.div`
  padding-right: 50px;
  &:last-child {
    padding-right: 0px;
  }

  ${props => props.theme.mediaQueries.sm} {
    padding-right: 50px;
  }

  ${props => props.theme.mediaQueries.md} {
    padding-right: 30px;
  }

  ${props => props.theme.mediaQueries.lg} {
    padding-right: 50px;
  }
`

const MediaContainerStyled = styled.div`
  width: 100%;
  ${props => props.theme.mediaQueries.md} {
    width: 40%;
  }
  ${props => props.theme.mediaQueries.lg} {
    width: 45%;
  }
`
const ImageStyled = styled(Img)({
  maxWidth: "100%",
})

const Contact = ({ address, phones, emails, image, children }) => {
  return (
    <Container>
      <ContactStyled>
        <ContentStyled>
          {children}
          <ContactInfoStyled>
            <ContactSection title="Adresse">
              <Typography>
                {address.street} <br /> {address.zipcode}
              </Typography>
            </ContactSection>
            <ContactSection title="Téléphone">
              <Typography>
                {phones.map(item => (
                  <Fragment key={`phone-${item.name}`}>
                    {item.name} : {item.phone} <br />
                  </Fragment>
                ))}
              </Typography>
            </ContactSection>
            <ContactSection title="Email">
              <Typography>
                {emails.map(email => (
                  <Fragment key={`email-${email}`}>
                    {email} <br />
                  </Fragment>
                ))}
              </Typography>
            </ContactSection>
          </ContactInfoStyled>
        </ContentStyled>
        <MediaContainerStyled>
          {image && (
            <ImageStyled
              fluid={image}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Locaux de Courtier Prêt Immo"
            />
          )}
        </MediaContainerStyled>
      </ContactStyled>
    </Container>
  )
}

Contact.propTypes = {
  children: PropTypes.any,
  address: PropTypes.shape({
    street: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
  }),
  phones: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    })
  ).isRequired,
  emails: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  image: PropTypes.object.isRequired,
}

export default Contact

const ContactSection = ({ children, title }) => {
  return (
    <SectionStyled>
      <Typography variant="small">{title}</Typography>
      <Separator variant="small" color={theme.colors.secondary} />
      {children}
    </SectionStyled>
  )
}

ContactSection.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
}
