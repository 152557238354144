import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link as GLink } from "gatsby"
import { Arrow } from "../Icon"

const StyledLink = styled(GLink)(props => {
  return {
    display: "inline-block",
    fontSize: 20,
    fontWeight: 700,
    color: props.theme.colors.secondary,
    "&:hover span + span": {
      transform: "translateX(10px)",
    },
  }
})

const StyledLinkText = styled("span")(() => ({}))
const StyledLinkIcon = styled("span")(props => ({
  display: "inline-block",
  transition: "all 0.2s",
  marginLeft: props.position === "end" ? 10 : 0,
  marginRight: props.position === "start" ? 10 : 0,
  marginTop: -1,
  verticalAlign: "top",
}))

const Link = ({ children, variant, iconPosition, ...props }) => {
  const renderIcon = () => {
    let icon = <Arrow />
    switch (variant) {
      case "arrow":
        icon = <Arrow />
        break
    }
    return icon ? (
      <StyledLinkIcon position={iconPosition}>{icon}</StyledLinkIcon>
    ) : null
  }

  return (
    <StyledLink position={iconPosition} {...props}>
      {iconPosition === "start" && renderIcon()}
      <StyledLinkText>{children}</StyledLinkText>
      {iconPosition === "end" && renderIcon()}
    </StyledLink>
  )
}

Link.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOf(["arrow"]),
  iconPosition: PropTypes.oneOf(["start", "end"]),
}

Link.defaultProps = {
  variant: "arrow",
  iconPosition: "end",
}

export default Link
