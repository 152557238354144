import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const ContainerStyled = styled.div`
  max-width: 90vw;
  margin: 0 auto;

  ${props => props.theme.mediaQueries.lg} {
    max-width: 1260px;
  }
`

const Container = ({ children }) => {
  return <ContainerStyled>{children}</ContainerStyled>
}

Container.propTypes = {
  children: PropTypes.any,
}

export default Container
