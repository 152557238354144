import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { Container } from ".."
import Img from "gatsby-image/withIEPolyfill"
import Link from "../Link"

const TitleStyled = styled.h2`
  position: relative;
  font-size: 35px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 30px;
`

const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 65px 0;
  flex-direction: column;

  ${props => props.theme.mediaQueries.sm} {
    flex-direction: row;
  }

  ${props =>
    props.teaser &&
    css`
      border-bottom: 1px solid #e7e7e7;
    `};
`

const ImageStyled = styled(Img)({
  maxWidth: "100%",
})

const ImageContainerStyled = styled.div`
  padding-bottom: 85px;
  width: 100%;
  text-align: center;

  ${props => props.theme.mediaQueries.sm} {
    width: 50%;
    padding-right: 85px;
    padding-bottom: 0;
  }

  ${props =>
    props.teaser &&
    css`
      padding-left: 85px;
    `};
`
const ContentStyled = styled.div`
  width: 100%;
  ${props => props.theme.mediaQueries.sm} {
    width: 50%;
  }
`
const ArticleStyled = styled.div``

const ChildrenStyled = styled.div`
  line-height: 30px;
  ${props =>
    props.teaser &&
    css`
      font-weight: 700;
      font-size: 22px;
      line-height: 34px;
      margin-bottom: 30px;
    `};
`

const Article = ({ title, content, teaser, image, link }) => {
  return (
    <ArticleStyled>
      <Container>
        <WrapperStyled teaser={teaser}>
          {image && (
            <ImageContainerStyled teaser={teaser}>
              <ImageStyled
                fluid={image}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={title}
              />
            </ImageContainerStyled>
          )}
          <ContentStyled>
            {!!teaser && <TitleStyled>{title}</TitleStyled>}
            <ChildrenStyled teaser={teaser}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </ChildrenStyled>
            {!!teaser && <Link to={link}>Lire la suite</Link>}
          </ContentStyled>
        </WrapperStyled>
      </Container>
    </ArticleStyled>
  )
}

Article.propTypes = {
  teaser: PropTypes.bool,
  image: PropTypes.object,
  title: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.any,
}

Article.defaultProps = {
  teaser: false,
}

export default Article
